
import React, { Fragment } from 'react'
import './nova-loja.scss'
// import { graphql, useStaticQuery } from 'gatsby'
// import RenderForm from 'gatsby-plugin-cloudofertas'
import FormNovaLoja from '../components/form-nova-loja/form-nova-loja'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import LocatorNovaLoja from '../components/Locator/LocatorNovaLoja.js'

const CupomInauguracao = ({ data }) => {
  console.log(data.inauguracaoLoja)

  return (
    <Fragment id="blackleste">
      <div className='fundo-cinza-claro' id='cupom'>
        <div className='container'>
          <h1 className='text-center' style={{ color: '#03588c', fontSize: '60px', fontWeight: 'bold' }}>Já conheceu a Drogaleste {data.inauguracaoLoja.name}?</h1>
          <p className='text-center' style={{ color: '#D9304F', fontSize: '40px' }}><b>{data.inauguracaoLoja.cupom_phrase}</b></p>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>1</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '37.5%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>{data.inauguracaoLoja.step_one}</p>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>2</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '80%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>{data.inauguracaoLoja.step_two}</p>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>3</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '80%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>{data.inauguracaoLoja.step_three}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-7 col-12'>
              <GatsbyImage
                image={data.mulhercomcelular.childImageSharp.gatsbyImageData}
                alt='Imagem teste covid'
                className='mulher-com-celular'
              />
            </div>
            <div className='col-lg-5 position-relative col-12 mt-5'>
              <FormNovaLoja loja={data.inauguracaoLoja.name} />
              <div>
                <p className='text-center mt-2 texto-mobile-final-cinza'>{data.inauguracaoLoja.observation}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', alignItems: 'center' }} className='d-flex justify-content-center'>
        <div className='localizacao-endereco'>
          <p className='texto-endereco'>{data.inauguracaoLoja.address}  - {data.inauguracaoLoja.district}</p>
        </div>
      </div>
      <div id='comochegar'>
        <LocatorNovaLoja data={data.inauguracaoLoja} />
      </div>
    </Fragment >
  )
}
export default CupomInauguracao
export const query = graphql`
query($slug: String!) {
  mulhercomcelular: file(relativePath: {eq: "images/novaloja/mulhercomcelular.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  inauguracaoLoja: cloudOfertasInauguracaoLoja(slug: { eq: $slug }) {
      id
      address
      cep
      city
      offer_url
      step_one
      step_two
      step_three
      cupom_phrase
      district
      flg_deleted
      inaugurationDate
      image {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      lat
      lng
      name
      slug
      status_inauguration
      observation
      uf
  }
}
`
